<template>
  <div class="flex gap-4">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="53px"
      height="16px"
      viewBox="0 0 53 16"
      enable-background="new 0 0 53 16"
      xml:space="preserve"
    >
      <g>
        <polygon
          fill="#2B3894"
          points="18.778,15.766 21.431,0.256 25.666,0.256 23.013,15.766 	"
        />
        <path
          fill="#2B3894"
          d="M38.352,0.642C37.518,0.342,36.189,0,34.564,0c-4.192,0-7.123,2.096-7.146,5.112
		c-0.021,2.225,2.097,3.465,3.7,4.214c1.646,0.77,2.204,1.24,2.182,1.925c0,1.048-1.305,1.519-2.523,1.519
		c-1.689,0-2.588-0.234-3.979-0.812L26.243,11.7l-0.577,3.466c0.983,0.449,2.802,0.812,4.706,0.834c4.448,0,7.315-2.075,7.358-5.305
		c0.021-1.754-1.113-3.103-3.553-4.214c-1.476-0.706-2.373-1.176-2.373-1.903c0-0.663,0.771-1.348,2.416-1.348
		c1.391-0.021,2.396,0.278,3.166,0.599L37.771,4L38.352,0.642L38.352,0.642z"
        />
        <path
          fill="#2B3894"
          d="M49.195,0.278h-3.272c-1.006,0-1.774,0.278-2.225,1.284L37.43,15.787h4.428c0,0,0.729-1.926,0.898-2.332
		c0.492,0,4.791,0,5.411,0c0.129,0.535,0.515,2.332,0.515,2.332h3.914L49.195,0.278L49.195,0.278z M43.977,10.268
		c0.342-0.877,1.689-4.322,1.689-4.322c-0.043,0.043,0.343-0.898,0.557-1.476L46.5,5.797c0,0,0.812,3.701,0.984,4.471H43.977
		L43.977,10.268z"
        />
        <path
          fill="#2B3894"
          d="M15.249,0.278l-4.15,10.568l-0.428-2.14C9.9,6.225,7.483,3.551,4.81,2.203l3.786,13.562h4.471l6.652-15.488
		H15.249L15.249,0.278z"
        />
        <path
          fill="#F79510"
          d="M7.27,0.256H0.446L0.403,0.599c5.284,1.283,8.813,4.385,10.268,8.107L9.174,1.583
		C8.917,0.599,8.189,0.3,7.27,0.256L7.27,0.256z"
        />
      </g>
    </svg>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="34px"
      height="20px"
      viewBox="0 0 34 20"
      enable-background="new 0 0 34 20"
      xml:space="preserve"
    >
      <g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FF9900"
          d="M17.008,17.408C18.759,19.021,21.111,20,23.669,20
		c5.494,0,9.94-4.48,9.94-9.991C33.609,4.48,29.163,0,23.669,0c-2.558,0-4.91,0.979-6.661,2.592
		c-2.009,1.837-3.278,4.481-3.278,7.417C13.729,12.944,14.999,15.588,17.008,17.408L17.008,17.408z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FCB131"
          d="M32.288,15.57c0-0.171,0.137-0.309,0.308-0.309
		c0.19,0,0.327,0.137,0.327,0.309c0,0.189-0.137,0.326-0.327,0.326C32.425,15.896,32.288,15.76,32.288,15.57L32.288,15.57z
		 M32.596,15.828c0.139,0,0.259-0.12,0.259-0.258c0-0.137-0.12-0.24-0.259-0.24c-0.12,0-0.239,0.103-0.239,0.24
		C32.356,15.708,32.476,15.828,32.596,15.828L32.596,15.828z M32.562,15.727h-0.068v-0.293h0.12c0.019,0,0.053,0,0.07,0.018
		c0.034,0.016,0.034,0.034,0.034,0.068s-0.018,0.068-0.052,0.068l0.068,0.139h-0.085l-0.036-0.121h-0.052V15.727v-0.173h0.034
		c0.018,0,0.036,0,0.036,0c0.018,0,0.018-0.017,0.018-0.034s0-0.018-0.018-0.034c0,0-0.019,0-0.036,0h-0.034v0.068V15.727
		L32.562,15.727z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#CC0000"
          d="M20.22,8.944c-0.034-0.361-0.103-0.704-0.173-1.065h-6.076
		c0.068-0.36,0.171-0.704,0.273-1.047h5.512c-0.12-0.36-0.259-0.721-0.413-1.065h-4.669c0.172-0.36,0.359-0.721,0.567-1.064h3.518
		c-0.223-0.36-0.479-0.721-0.755-1.047h-2.008c0.308-0.378,0.652-0.739,1.012-1.065C15.241,0.996,12.905,0,10.331,0
		c-5.478,0-9.94,4.48-9.94,10.009c0,5.511,4.463,9.991,9.94,9.991c2.574,0,4.91-0.979,6.677-2.592l0,0l0,0
		c0.361-0.326,0.688-0.67,0.996-1.048h-2.008c-0.276-0.343-0.516-0.687-0.755-1.064h3.518c0.225-0.343,0.413-0.687,0.584-1.064
		h-4.669c-0.155-0.343-0.31-0.688-0.43-1.048h5.512c0.119-0.343,0.205-0.704,0.291-1.064c0.07-0.343,0.139-0.705,0.173-1.065
		c0.034-0.343,0.051-0.687,0.051-1.047C20.271,9.648,20.254,9.288,20.22,8.944L20.22,8.944z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M32.288,12.326c0-0.188,0.137-0.325,0.308-0.325
		c0.19,0,0.327,0.137,0.327,0.325c0,0.172-0.137,0.327-0.327,0.327C32.425,12.653,32.288,12.498,32.288,12.326L32.288,12.326z
		 M32.596,12.566c0.139,0,0.259-0.103,0.259-0.241c0-0.137-0.12-0.24-0.259-0.24c-0.12,0-0.239,0.103-0.239,0.24
		C32.356,12.464,32.476,12.566,32.596,12.566L32.596,12.566z M32.562,12.464h-0.068v-0.275h0.12c0.019,0,0.053,0,0.07,0
		c0.034,0.017,0.034,0.051,0.034,0.085c0,0.017-0.018,0.051-0.052,0.069l0.068,0.12h-0.085l-0.036-0.103h-0.052V12.464V12.31h0.034
		c0.018,0,0.036,0,0.036-0.018c0.018,0,0.018-0.017,0.018-0.034c0,0,0-0.017-0.018-0.017c0-0.017-0.019,0-0.036,0h-0.034v0.069
		V12.464L32.562,12.464z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          d="M13.626,12.893c-0.325,0.085-0.566,0.138-0.806,0.138
		c-0.516,0-0.825-0.326-0.825-0.91c0-0.12,0.019-0.241,0.036-0.378l0.068-0.396l0.051-0.326l0.463-2.815h1.03l-0.12,0.618h0.652
		l-0.154,1.031H13.37l-0.276,1.682c-0.017,0.085-0.017,0.137-0.017,0.171c0,0.224,0.103,0.309,0.361,0.309
		c0.119,0,0.223-0.016,0.325-0.034L13.626,12.893L13.626,12.893z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          d="M16.956,12.858c-0.376,0.104-0.721,0.155-1.097,0.155
		c-1.168,0-1.838-0.618-1.838-1.837c0-1.425,0.79-2.455,1.889-2.455c0.875,0,1.441,0.584,1.441,1.494
		c0,0.309-0.034,0.601-0.119,1.013h-2.147c-0.017,0.068-0.017,0.103-0.017,0.137c0,0.481,0.326,0.722,0.944,0.722
		c0.396,0,0.738-0.085,1.116-0.257L16.956,12.858L16.956,12.858z M16.321,10.403c0-0.086,0-0.154,0-0.206
		c0-0.343-0.188-0.532-0.514-0.532c-0.344,0-0.584,0.257-0.688,0.738H16.321L16.321,10.403z"
        />
        <polygon
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          points="5.387,12.944 4.321,12.944 4.939,9.047 3.55,12.944
		2.812,12.944 2.726,9.064 2.073,12.944 1.026,12.944 1.866,7.879 3.43,7.879 3.464,11.004 4.51,7.879 6.227,7.879 5.387,12.944
		"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          d="M7.979,11.107c-0.103-0.016-0.137-0.016-0.205-0.016
		c-0.618,0-0.929,0.239-0.929,0.617c0,0.258,0.154,0.43,0.396,0.43C7.756,12.138,7.961,11.708,7.979,11.107L7.979,11.107z
		 M8.836,12.944H7.91l0.017-0.429c-0.239,0.326-0.566,0.498-1.133,0.498c-0.516,0-0.961-0.464-0.961-1.133
		c0-0.188,0.034-0.36,0.086-0.533c0.171-0.635,0.807-1.03,1.785-1.047c0.12,0,0.309,0,0.479,0.017
		c0.034-0.138,0.034-0.188,0.034-0.274c0-0.275-0.223-0.36-0.721-0.36c-0.308,0-0.652,0.051-0.892,0.137L6.451,9.854L6.383,9.872
		l0.154-0.927C7.035,8.79,7.395,8.721,7.79,8.721c0.926,0,1.425,0.429,1.425,1.219c0,0.206,0.017,0.36-0.052,0.807l-0.239,1.477
		L8.89,12.481l-0.02,0.206l-0.017,0.137L8.836,12.944L8.836,12.944z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          d="M22.143,8.858c0.308,0,0.583,0.086,0.96,0.275l0.19-1.098
		c-0.104-0.051-0.139-0.051-0.276-0.104l-0.43-0.12C22.45,7.777,22.279,7.76,22.091,7.76c-0.532,0-0.843,0.017-1.168,0.207
		c-0.171,0.12-0.396,0.274-0.635,0.548L20.151,8.48l-1.1,0.773l0.052-0.43H17.97l-0.669,4.12h1.082l0.394-2.214
		c0,0,0.155-0.309,0.225-0.412c0.205-0.257,0.376-0.257,0.601-0.257c0.034,0,0.068,0,0.086,0c-0.034,0.24-0.052,0.498-0.052,0.79
		c0,1.339,0.755,2.18,1.923,2.18c0.291,0,0.55-0.034,0.943-0.138l0.189-1.167C22.348,11.914,22.039,12,21.781,12
		c-0.636,0-1.012-0.481-1.012-1.237C20.77,9.648,21.334,8.858,22.143,8.858L22.143,8.858z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          d="M31.257,7.879l-0.239,1.443c-0.257-0.395-0.567-0.584-0.979-0.584
		c-0.566,0-1.098,0.326-1.425,0.944V9.666l-0.687-0.412l0.068-0.43h-1.15l-0.652,4.12h1.065l0.361-2.214
		c0,0,0.273-0.309,0.342-0.412c0.172-0.206,0.345-0.24,0.481-0.257c-0.12,0.343-0.188,0.756-0.188,1.219
		c0,1.031,0.531,1.717,1.322,1.717c0.394,0,0.703-0.138,0.994-0.464l-0.051,0.412h1.013l0.824-5.064H31.257L31.257,7.879z
		 M29.936,11.965c-0.359,0-0.55-0.274-0.55-0.806c0-0.807,0.344-1.391,0.843-1.391c0.376,0,0.566,0.292,0.566,0.807
		C30.795,11.399,30.45,11.965,29.936,11.965L29.936,11.965z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          d="M24.974,11.107c-0.103-0.016-0.137-0.016-0.205-0.016
		c-0.618,0-0.927,0.239-0.927,0.617c0,0.258,0.154,0.43,0.395,0.43C24.751,12.138,24.957,11.708,24.974,11.107L24.974,11.107z
		 M25.833,12.944h-0.945l0.035-0.429c-0.24,0.326-0.567,0.498-1.134,0.498c-0.53,0-0.995-0.447-0.995-1.133
		c0-0.979,0.738-1.58,1.906-1.58c0.119,0,0.308,0,0.464,0.017c0.034-0.138,0.051-0.188,0.051-0.274c0-0.275-0.224-0.36-0.739-0.36
		c-0.291,0-0.652,0.051-0.892,0.137l-0.138,0.035l-0.068,0.017l0.154-0.927c0.498-0.155,0.857-0.223,1.253-0.223
		c0.927,0,1.407,0.429,1.407,1.219c0,0.206,0.034,0.36-0.051,0.807l-0.223,1.477l-0.034,0.258l-0.034,0.206l-0.018,0.137V12.944
		L25.833,12.944z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          d="M11.086,9.7c0.205,0,0.498,0.017,0.806,0.068l0.156-0.961
		c-0.31-0.034-0.723-0.085-0.962-0.085c-1.202,0-1.598,0.652-1.598,1.408c0,0.497,0.225,0.857,0.809,1.132
		c0.428,0.207,0.498,0.241,0.498,0.43c0,0.257-0.225,0.412-0.636,0.412c-0.326,0-0.635-0.051-0.979-0.172l-0.12,0.944l0.017,0.017
		l0.206,0.035c0.068,0.016,0.153,0.034,0.275,0.05c0.257,0.018,0.479,0.035,0.618,0.035c1.202,0,1.698-0.464,1.698-1.374
		c0-0.566-0.273-0.91-0.807-1.15c-0.464-0.206-0.515-0.24-0.515-0.429C10.554,9.872,10.759,9.7,11.086,9.7L11.086,9.7z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M23.584,7.708l-0.188,1.098c-0.379-0.188-0.652-0.275-0.963-0.275
		c-0.806,0-1.372,0.791-1.372,1.906c0,0.773,0.378,1.236,1.013,1.236c0.257,0,0.567-0.085,0.909-0.257l-0.188,1.15
		c-0.396,0.103-0.651,0.155-0.944,0.155c-1.168,0-1.889-0.841-1.889-2.197c0-1.803,0.996-3.073,2.421-3.073
		c0.188,0,0.361,0.017,0.498,0.051l0.43,0.103C23.446,7.657,23.48,7.674,23.584,7.708L23.584,7.708z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M20.117,8.463c-0.036,0-0.07,0-0.104,0
		c-0.359,0-0.567,0.172-0.893,0.669l0.103-0.635h-0.978l-0.671,4.121h1.082c0.396-2.524,0.498-2.953,1.014-2.953
		c0.034,0,0.034,0,0.068,0c0.103-0.499,0.24-0.859,0.413-1.185L20.117,8.463L20.117,8.463z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M13.9,12.566c-0.291,0.103-0.53,0.138-0.772,0.138
		c-0.55,0-0.857-0.309-0.857-0.91c0-0.103,0.018-0.241,0.034-0.36l0.068-0.413l0.052-0.326l0.464-2.815h1.064l-0.121,0.618h0.55
		L14.244,9.51h-0.549l-0.291,1.717c-0.018,0.068-0.018,0.12-0.018,0.171c0,0.206,0.103,0.292,0.359,0.292
		c0.12,0,0.225,0,0.293-0.034L13.9,12.566L13.9,12.566z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M9.764,9.802c0,0.515,0.24,0.875,0.807,1.15
		c0.447,0.207,0.516,0.275,0.516,0.446c0,0.258-0.188,0.378-0.618,0.378c-0.325,0-0.618-0.052-0.96-0.155l-0.156,0.944l0.051,0.017
		l0.19,0.035c0.068,0.016,0.154,0.034,0.291,0.034c0.239,0.034,0.447,0.034,0.584,0.034c1.134,0,1.666-0.43,1.666-1.374
		c0-0.566-0.225-0.893-0.755-1.149c-0.464-0.207-0.516-0.258-0.516-0.447c0-0.224,0.188-0.326,0.532-0.326
		c0.206,0,0.496,0.017,0.772,0.051l0.154-0.944c-0.274-0.051-0.704-0.085-0.943-0.085C10.177,8.412,9.747,9.047,9.764,9.802
		L9.764,9.802z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M31.807,12.619h-1.012l0.051-0.396
		c-0.292,0.309-0.601,0.446-0.996,0.446c-0.789,0-1.305-0.668-1.305-1.699c0-1.373,0.807-2.541,1.752-2.541
		c0.43,0,0.737,0.189,1.028,0.567l0.242-1.442h1.064L31.807,12.619L31.807,12.619z M30.229,11.657c0.498,0,0.84-0.584,0.84-1.391
		c0-0.532-0.188-0.807-0.566-0.807c-0.481,0-0.841,0.567-0.841,1.374C29.661,11.382,29.85,11.657,30.229,11.657L30.229,11.657z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M17.198,12.532c-0.379,0.121-0.722,0.172-1.117,0.172
		c-1.201,0-1.819-0.635-1.819-1.854c0-1.408,0.789-2.455,1.871-2.455c0.894,0,1.459,0.584,1.459,1.494c0,0.309-0.034,0.6-0.138,1.03
		h-2.128c-0.017,0.051-0.017,0.085-0.017,0.12c0,0.481,0.325,0.722,0.943,0.722c0.396,0,0.739-0.068,1.116-0.257L17.198,12.532
		L17.198,12.532z M16.597,10.077c0-0.086,0-0.154,0-0.206c0-0.343-0.188-0.532-0.516-0.532c-0.344,0-0.583,0.257-0.687,0.738H16.597
		L16.597,10.077z"
        />
        <polygon
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          points="5.66,12.619 4.598,12.619 5.215,8.721 3.823,12.619
		3.085,12.619 3,8.755 2.348,12.619 1.351,12.619 2.193,7.554 3.737,7.554 3.789,10.695 4.819,7.554 6.503,7.554 5.66,12.619 	"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M8.322,10.782c-0.104,0-0.155-0.018-0.241-0.018
		c-0.601,0-0.909,0.223-0.909,0.635c0,0.258,0.137,0.413,0.377,0.413C7.995,11.812,8.306,11.399,8.322,10.782L8.322,10.782z
		 M9.112,12.619H8.218l0.018-0.43c-0.274,0.343-0.636,0.498-1.132,0.498c-0.584,0-0.979-0.447-0.979-1.116
		c0-1.013,0.687-1.597,1.889-1.597c0.12,0,0.275,0.017,0.447,0.035c0.034-0.137,0.034-0.189,0.034-0.258
		c0-0.275-0.188-0.377-0.687-0.377c-0.311,0-0.652,0.034-0.895,0.103L6.76,9.528L6.657,9.545l0.153-0.927
		c0.532-0.155,0.894-0.207,1.288-0.207c0.928,0,1.426,0.412,1.426,1.202c0,0.206-0.017,0.36-0.088,0.824l-0.239,1.459l-0.034,0.258
		l-0.017,0.206l-0.018,0.154L9.112,12.619L9.112,12.619z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M25.318,10.782c-0.12,0-0.172-0.018-0.242-0.018
		c-0.618,0-0.926,0.223-0.926,0.635c0,0.258,0.154,0.413,0.396,0.413C24.974,11.812,25.301,11.399,25.318,10.782L25.318,10.782z
		 M26.107,12.619h-0.893l0.018-0.43c-0.275,0.343-0.635,0.498-1.134,0.498c-0.583,0-0.979-0.447-0.979-1.116
		c0-1.013,0.687-1.597,1.889-1.597c0.122,0,0.276,0.017,0.43,0.035c0.034-0.137,0.052-0.189,0.052-0.258
		c0-0.275-0.188-0.377-0.687-0.377c-0.309,0-0.67,0.034-0.909,0.103l-0.139,0.052l-0.103,0.017l0.153-0.927
		c0.533-0.155,0.895-0.207,1.288-0.207c0.928,0,1.409,0.412,1.409,1.202c0,0.206,0,0.36-0.086,0.824l-0.225,1.459l-0.034,0.258
		l-0.034,0.206l-0.017,0.154V12.619L26.107,12.619z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M28.992,8.463c-0.034,0-0.068,0-0.103,0
		c-0.361,0-0.567,0.172-0.895,0.669l0.104-0.635h-0.978l-0.652,4.121h1.062c0.396-2.524,0.498-2.953,1.014-2.953
		c0.034,0,0.034,0,0.068,0c0.103-0.499,0.24-0.859,0.413-1.185L28.992,8.463L28.992,8.463z"
        />
      </g>
    </svg>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="34px"
      height="20px"
      viewBox="0 0 34 20"
      enable-background="new 0 0 34 20"
      xml:space="preserve"
    >
      <g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#0066CB"
          d="M17,17.415c2.018-1.822,3.291-4.467,3.291-7.405
		c0-2.938-1.273-5.583-3.291-7.424C15.237,0.979,12.886,0,10.3,0C4.776,0,0.291,4.486,0.291,10.01c0,5.524,4.485,9.99,10.01,9.99
		C12.886,20,15.237,19.021,17,17.415L17,17.415z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#0066CB"
          d="M17,17.415c2.018-1.822,3.291-4.467,3.291-7.405
		c0-2.938-1.273-5.583-3.291-7.424V17.415L17,17.415z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#CC0001"
          d="M23.719,0C21.133,0,18.783,0.979,17,2.586
		c-0.354,0.333-0.686,0.686-1,1.058h2.018c0.275,0.333,0.529,0.706,0.766,1.058h-3.546c-0.216,0.353-0.411,0.705-0.588,1.058h4.701
		c0.158,0.353,0.314,0.705,0.432,1.058h-5.543c-0.117,0.353-0.216,0.706-0.294,1.078h6.13c0.139,0.666,0.217,1.391,0.217,2.116
		c0,1.097-0.176,2.174-0.51,3.173h-5.543c0.117,0.353,0.255,0.705,0.411,1.058h4.701c-0.176,0.353-0.352,0.725-0.566,1.058h-3.546
		c0.235,0.372,0.49,0.725,0.763,1.059h2.018c-0.312,0.371-0.646,0.725-1.018,1.058C18.783,19.021,21.133,20,23.719,20
		c5.523,0,9.99-4.466,9.99-9.99C33.709,4.486,29.242,0,23.719,0L23.719,0z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#ED1C2E"
          d="M32.436,15.573c0-0.176,0.137-0.313,0.314-0.313
		c0.176,0,0.312,0.137,0.312,0.313c0,0.177-0.137,0.333-0.312,0.333C32.572,15.906,32.436,15.75,32.436,15.573L32.436,15.573z
		 M32.75,15.828c0.137,0,0.254-0.117,0.254-0.255c0-0.137-0.117-0.235-0.254-0.235c-0.139,0-0.256,0.098-0.256,0.235
		C32.494,15.711,32.611,15.828,32.75,15.828L32.75,15.828z M32.709,15.711h-0.078v-0.275h0.119c0.039,0,0.059,0,0.078,0.02
		c0.02,0,0.039,0.039,0.039,0.059c0,0.039-0.02,0.059-0.039,0.078l0.039,0.118h-0.078l-0.039-0.099h-0.041V15.711L32.709,15.711z
		 M32.709,15.554h0.041c0,0,0.02,0,0.039,0c0,0,0.02-0.02,0.02-0.039l-0.02-0.02c-0.02-0.019-0.039-0.019-0.039-0.019h-0.041V15.554
		L32.709,15.554z"
        />
        <polygon
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          points="8.733,12.792 7.48,12.792 8.224,8.894 6.52,12.792
		5.462,12.792 5.247,8.913 4.423,12.792 3.288,12.792 4.248,7.718 6.226,7.718 6.246,10.852 7.617,7.718 9.712,7.718 8.733,12.792
		"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          d="M22.641,12.733c-0.352,0.098-0.607,0.137-0.9,0.137
		c-0.627,0-0.98-0.313-0.98-0.901c0-0.118,0.021-0.254,0.041-0.372l0.078-0.411l0.059-0.313l0.568-3.154h1.232l-0.176,0.94h0.627
		l-0.156,1h-0.646l-0.334,1.724c0,0.079-0.02,0.137-0.02,0.176c0,0.216,0.139,0.293,0.432,0.293c0.137,0,0.254,0,0.334-0.039
		L22.641,12.733L22.641,12.733z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          d="M17.705,9.971c0,0.509,0.293,0.881,0.939,1.136
		c0.529,0.216,0.607,0.274,0.607,0.47c0,0.255-0.234,0.373-0.725,0.373c-0.391,0-0.725-0.059-1.137-0.176l-0.176,0.959h0.059
		l0.236,0.04c0.078,0.019,0.176,0.039,0.332,0.039c0.295,0.02,0.529,0.039,0.686,0.039c1.332,0,1.939-0.431,1.939-1.371
		c0-0.568-0.254-0.901-0.881-1.155c-0.529-0.196-0.588-0.255-0.588-0.451c0-0.215,0.215-0.333,0.607-0.333
		c0.254,0,0.588,0.02,0.92,0.059l0.176-0.94c-0.332-0.059-0.822-0.098-1.115-0.098C18.176,8.561,17.686,9.207,17.705,9.971
		L17.705,9.971z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          d="M12.769,12.792H11.73l0.021-0.431
		c-0.313,0.333-0.745,0.489-1.313,0.489c-0.665,0-1.136-0.45-1.136-1.116c0-0.999,0.804-1.586,2.194-1.586
		c0.137,0,0.313,0,0.509,0.019c0.039-0.137,0.039-0.195,0.039-0.254c0-0.274-0.215-0.392-0.803-0.392
		c-0.353,0-0.744,0.059-1.019,0.117l-0.177,0.059l-0.117,0.02l0.177-0.921c0.626-0.176,1.038-0.235,1.488-0.235
		c1.077,0,1.646,0.431,1.646,1.214c0,0.216-0.02,0.353-0.117,0.823l-0.255,1.45l-0.039,0.274l-0.039,0.215l-0.02,0.137V12.792
		L12.769,12.792z M11.926,10.951c-0.137-0.02-0.196-0.02-0.294-0.02c-0.705,0-1.058,0.216-1.058,0.627
		c0,0.255,0.176,0.411,0.45,0.411C11.535,11.969,11.907,11.558,11.926,10.951L11.926,10.951z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          d="M16.844,12.694c-0.412,0.117-0.824,0.176-1.273,0.176
		c-1.391,0-2.116-0.646-2.116-1.861c0-1.411,0.92-2.449,2.174-2.449c1.037,0,1.684,0.587,1.684,1.489
		c0,0.313-0.039,0.607-0.156,1.019h-2.468c-0.02,0.079-0.02,0.098-0.02,0.137c0,0.47,0.372,0.725,1.097,0.725
		c0.451,0,0.842-0.079,1.293-0.274L16.844,12.694L16.844,12.694z M16.256,10.245c0-0.098,0-0.157,0-0.215
		c0-0.333-0.217-0.529-0.588-0.529c-0.392,0-0.686,0.254-0.803,0.744H16.256L16.256,10.245z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          d="M30.125,10.911c-0.178,1.371-1.137,1.979-2.41,1.979
		c-1.41,0-1.979-0.842-1.979-1.9c0-1.45,0.959-2.449,2.449-2.449c1.293,0,1.979,0.823,1.979,1.861
		C30.164,10.656,30.164,10.676,30.125,10.911L30.125,10.911z M28.871,10.421c0-0.431-0.178-0.842-0.686-0.842
		c-0.627,0-1.039,0.764-1.039,1.411c0,0.568,0.275,0.94,0.725,0.94c0.275,0,0.842-0.372,0.961-1.019
		C28.852,10.774,28.871,10.598,28.871,10.421L28.871,10.421z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M30.262,12.165c0-0.176,0.156-0.313,0.332-0.313
		c0.178,0,0.314,0.137,0.314,0.313c0,0.177-0.137,0.333-0.314,0.333C30.418,12.498,30.262,12.341,30.262,12.165L30.262,12.165z
		 M30.594,12.419c0.139,0,0.236-0.118,0.236-0.255c0-0.137-0.098-0.235-0.236-0.235c-0.137,0-0.254,0.098-0.254,0.235
		C30.34,12.302,30.457,12.419,30.594,12.419L30.594,12.419z M30.555,12.302h-0.078v-0.274h0.117c0.039,0,0.059,0,0.078,0.02
		s0.039,0.039,0.039,0.059c0,0.04-0.02,0.079-0.059,0.079l0.059,0.117h-0.078l-0.039-0.098h-0.039V12.302L30.555,12.302z
		 M30.555,12.146h0.039c0,0,0.02,0,0.039,0c0,0,0-0.02,0-0.04v-0.02c-0.02,0-0.039,0-0.059,0h-0.02V12.146L30.555,12.146z"
        />
        <polygon
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          points="9.046,12.498 7.793,12.498 8.538,8.6 6.833,12.498
		5.697,12.498 5.501,8.619 4.757,12.498 3.621,12.498 4.581,7.424 6.54,7.424 6.637,10.559 8.008,7.424 10.026,7.424 9.046,12.498
		"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M22.955,12.439c-0.354,0.098-0.607,0.137-0.902,0.137
		c-0.625,0-0.979-0.313-0.979-0.901c0-0.118,0.02-0.235,0.039-0.373l0.078-0.411l0.059-0.313l0.568-3.154h1.234l-0.176,0.94h0.646
		l-0.178,0.999h-0.646l-0.312,1.724c-0.02,0.078-0.039,0.137-0.039,0.176c0,0.215,0.137,0.313,0.432,0.313
		c0.137,0,0.254-0.02,0.332-0.04L22.955,12.439L22.955,12.439z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#003473"
          d="M26.148,9.56l-0.02-0.137l0.234-0.646
		c-0.059,0-0.078,0-0.098-0.02c-0.059,0-0.098-0.02-0.098-0.02c-0.039,0-0.078,0-0.117,0c-0.412,0-0.707,0.176-1.098,0.666
		l0.137-0.745h-1.293l-0.764,4.133h1.234c0.176-0.959,0.254-1.489,0.352-1.939c0.158-0.744,0.705-1.077,1.117-0.999
		c0.039,0,0.078,0,0.137,0.02l0.078,0.039L26.148,9.56L26.148,9.56z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M26.578,8.404c-0.098-0.039-0.117-0.039-0.137-0.039
		c-0.059-0.02-0.098-0.02-0.098-0.039c-0.039,0-0.078,0-0.137,0c-0.412,0-0.705,0.176-1.078,0.666l0.117-0.626h-1.135l-0.766,4.133
		H24.6c0.451-2.527,0.627-2.978,1.234-2.978c0.039,0,0.098,0.02,0.156,0.02l0.139,0.039L26.578,8.404L26.578,8.404z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M18.018,9.677c0,0.509,0.295,0.881,0.961,1.136
		c0.51,0.215,0.588,0.274,0.588,0.47c0,0.254-0.236,0.372-0.725,0.372c-0.373,0-0.725-0.059-1.137-0.176l-0.156,0.96h0.059
		l0.215,0.059c0.078,0,0.195,0.02,0.334,0.02c0.293,0.039,0.527,0.039,0.705,0.039c1.312,0,1.939-0.431,1.939-1.371
		c0-0.568-0.256-0.901-0.902-1.156c-0.529-0.196-0.588-0.255-0.588-0.451c0-0.215,0.217-0.333,0.627-0.333
		c0.236,0,0.588,0.02,0.902,0.059l0.176-0.94c-0.334-0.059-0.822-0.079-1.098-0.079C18.508,8.286,18.018,8.913,18.018,9.677
		L18.018,9.677z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M13.083,12.498h-1.02l0.02-0.431
		c-0.312,0.333-0.744,0.49-1.312,0.49c-0.665,0-1.136-0.451-1.136-1.117c0-0.999,0.803-1.586,2.194-1.586
		c0.137,0,0.313,0,0.509,0.02c0.039-0.137,0.039-0.196,0.039-0.255c0-0.274-0.215-0.372-0.803-0.372
		c-0.353,0-0.744,0.039-1.019,0.098l-0.177,0.059l-0.117,0.02l0.176-0.92c0.627-0.157,1.02-0.216,1.489-0.216
		c1.077,0,1.626,0.412,1.626,1.195c0,0.216-0.02,0.373-0.098,0.823l-0.255,1.47l-0.039,0.254l-0.039,0.215l-0.02,0.137
		L13.083,12.498L13.083,12.498z M12.181,10.656c-0.138-0.019-0.196-0.019-0.274-0.019c-0.705,0-1.058,0.215-1.058,0.626
		c0,0.255,0.156,0.431,0.431,0.431C11.79,11.695,12.162,11.264,12.181,10.656L12.181,10.656z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M17.176,12.4c-0.432,0.118-0.842,0.176-1.273,0.176
		c-1.39,0-2.115-0.646-2.115-1.861c0-1.411,0.92-2.449,2.173-2.449c1.02,0,1.666,0.588,1.666,1.489c0,0.313-0.039,0.607-0.137,1.019
		h-2.489c0,0.078,0,0.098,0,0.137c0,0.47,0.353,0.725,1.079,0.725c0.449,0,0.861-0.079,1.291-0.255L17.176,12.4L17.176,12.4z
		 M16.51,9.951c0-0.078,0-0.157,0-0.215c0-0.333-0.215-0.529-0.588-0.529c-0.391,0-0.666,0.274-0.783,0.744H16.51L16.51,9.951z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          d="M30.457,10.617c-0.195,1.371-1.156,1.979-2.43,1.979
		c-1.391,0-1.959-0.842-1.959-1.9c0-1.45,0.961-2.449,2.449-2.449c1.293,0,1.979,0.823,1.979,1.861
		C30.496,10.363,30.496,10.382,30.457,10.617L30.457,10.617z M29.164,10.088c0-0.431-0.176-0.823-0.686-0.823
		c-0.627,0-1.018,0.745-1.018,1.411c0,0.548,0.273,0.92,0.705,0.92c0.273,0,0.861-0.372,0.959-0.999
		C29.164,10.441,29.164,10.265,29.164,10.088L29.164,10.088z"
        />
      </g>
    </svg>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="48px"
      height="23px"
      viewBox="0 0 48 23"
      enable-background="new 0 0 48 23"
      xml:space="preserve"
    >
      <g>
        <polygon
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#2A2A6C"
          points="16.812,15.624 19.23,0.675 23.095,0.675 20.678,15.624
		16.812,15.624 	"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#2A2A6C"
          d="M34.689,1.04c-0.763-0.302-1.972-0.636-3.467-0.636
		c-3.816,0-6.506,2.036-6.521,4.946c-0.031,2.146,1.925,3.34,3.389,4.056c1.494,0.731,2.004,1.208,2.004,1.86
		c-0.016,1.002-1.209,1.463-2.322,1.463c-1.543,0-2.371-0.238-3.627-0.795l-0.509-0.238l-0.541,3.355
		c0.907,0.413,2.577,0.779,4.312,0.795c4.055,0,6.695-2.004,6.728-5.104c0.016-1.702-1.019-3.007-3.245-4.071
		c-1.352-0.685-2.18-1.146-2.18-1.846c0.017-0.636,0.701-1.288,2.227-1.288c1.258-0.031,2.18,0.271,2.88,0.573l0.351,0.158
		L34.689,1.04L34.689,1.04z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#2A2A6C"
          d="M39.828,10.328c0.318-0.858,1.542-4.183,1.542-4.183
		c-0.015,0.032,0.318-0.875,0.509-1.432l0.271,1.288c0,0,0.73,3.579,0.891,4.326C42.436,10.328,40.592,10.328,39.828,10.328
		L39.828,10.328z M44.6,0.675h-2.99c-0.923,0-1.623,0.271-2.021,1.24l-5.741,13.709h4.055c0,0,0.668-1.845,0.812-2.242
		c0.445,0,4.389,0,4.963,0c0.11,0.525,0.46,2.242,0.46,2.242h3.579L44.6,0.675L44.6,0.675z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#2A2A6C"
          d="M13.584,0.675L9.798,10.869L9.384,8.802
		c-0.7-2.386-2.895-4.978-5.344-6.267l3.467,13.073h4.087l6.077-14.934H13.584L13.584,0.675z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#2A2A6C"
          d="M6.283,0.675H0.064L0,0.977c4.851,1.241,8.064,4.23,9.384,7.825
		L8.032,1.931C7.81,0.977,7.126,0.706,6.283,0.675L6.283,0.675z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#2A2A6C"
          d="M18.559,22.494h0.641v-0.003l1.691-0.011l0.085-0.635l-1.689,0.01
		l0.187-1.36l1.286-0.011l0.085-0.635l-1.285,0.01l0.167-1.224l1.637-0.01l0.085-0.636l-2.275,0.014L18.559,22.494L18.559,22.494z
		 M24.738,22.494h0.641v-0.003l1.691-0.011l0.086-0.635l-1.689,0.01l0.186-1.36l1.286-0.011l0.085-0.635l-1.284,0.01l0.167-1.224
		l1.636-0.01l0.085-0.636l-2.275,0.014L24.738,22.494L24.738,22.494z M21.682,22.494h0.641v-0.003l1.691-0.011l0.086-0.635
		l-1.689,0.01l0.593-3.865l-0.708,0.014L21.682,22.494L21.682,22.494z M32.874,22.494h0.642v-0.003l0.047-0.011l0.489-3.845
		l1.404-0.01l0.085-0.636l-3.31,0.014l-0.086,0.632h1.263L32.874,22.494L32.874,22.494z M35.609,22.494l0.641-0.014l0,0h0.046
		l0.235-1.899h0.346l1.034,1.899h0.692l-1.014-1.898c1.273-0.313,1.642-2.607,0.051-2.592l-1.363,0.014L35.609,22.494L35.609,22.494
		z M36.621,20.028h0.737c0.589,0,0.95-1.47,0.241-1.479l-0.789-0.011L36.621,20.028L36.621,20.028z M41.709,17.839
		c1.076,0,1.824,1.065,1.672,2.379s-1.148,2.378-2.225,2.378s-1.824-1.064-1.673-2.378C39.637,18.904,40.633,17.839,41.709,17.839
		L41.709,17.839z M41.632,18.375c0.778,0,1.321,0.893,1.21,1.843c-0.109,0.949-0.84,1.842-1.619,1.842
		c-0.777,0-1.311-0.893-1.199-1.842C40.133,19.268,40.854,18.375,41.632,18.375L41.632,18.375z M43.977,22.494l0.641-0.014h0.001
		h0.046l0.492-3.471l1.686,3.471h0.535L48,18.004h-0.605l-0.51,3.23l-1.561-3.23h-0.623L43.977,22.494L43.977,22.494z M30.32,17.839
		c0.486,0,0.907,0.201,1.217,0.536l-0.344,0.595c-0.228-0.293-0.561-0.473-0.953-0.473c-0.809,0-1.558,0.771-1.672,1.721
		c-0.114,0.949,0.449,1.72,1.258,1.72c0.402,0,0.787-0.189,1.09-0.496v0.758c-0.352,0.251-0.754,0.396-1.169,0.396
		c-1.118,0-1.897-1.064-1.739-2.378S29.201,17.839,30.32,17.839L30.32,17.839z"
        />
      </g>
    </svg>
  </div>
</template>
