<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_16334_136955)">
      <path d="M12 18V21" stroke-width="2" stroke-miterlimit="10" />
      <path d="M16 21V3H8V21" stroke-width="1.5" stroke-miterlimit="10" />
      <path d="M16 5H21V21H3V5H8" stroke-width="1.5" stroke-miterlimit="10" />
      <path d="M11 15H13" stroke-width="2" stroke-miterlimit="10" />
      <path d="M11 11H13" stroke-width="2" stroke-miterlimit="10" />
      <path d="M11 7H13" stroke-width="2" stroke-miterlimit="10" />
      <path d="M6 17H8" stroke-width="2" stroke-miterlimit="10" />
      <path d="M6 13H7.5" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M6 9H8"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M18 9H16"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M18 13H16"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M18 17H16"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_16334_136955">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
</template>
