<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="currentColor"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_18937_129523)">
      <path d="M10 3H3V9H10V3Z" stroke-width="1.5" stroke-miterlimit="10" />
      <path d="M21 3H14V9H21V3Z" stroke-width="1.5" stroke-miterlimit="10" />
      <path d="M2 13H22" stroke-width="1.5" stroke-miterlimit="10" />
      <path d="M2 17H17.5" stroke-width="1.5" stroke-miterlimit="10" />
      <path d="M2 21H22" stroke-width="1.5" stroke-miterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_18937_129523">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
</template>
