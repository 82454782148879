<template>
  <div>
    <h5 class="label-lg mb-3 text-black">
      <slot name="heading" />
    </h5>

    <nav class="list-none space-y-3">
      <slot />
    </nav>
  </div>
</template>
