import { useMutation } from "@tanstack/vue-query";

interface OrderTarifResponse {
  id: number;
  currency: string;
}

export const useShortcutBuyTarif = () => {
  return useMutation({
    mutationFn: (variables: {
      companySlug: string;
      tier: "basic" | "extra";
      duration: 30 | 365;
    }) => {
      return $apiFetch<OrderTarifResponse>(
        `/admin-api/${variables.companySlug}/order-tarif`,
        {
          method: "POST",
          body: {
            payload: {
              tier: variables.tier,
              duration: variables.duration,
            },
          },
        },
      );
    },
  });
};
