<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7588 15.3305C21.7588 14.4905 21.0788 13.8105 20.2388 13.8105C19.3988 13.8105 18.7188 14.4905 18.7188 15.3305C18.7188 16.1705 19.3988 16.8505 20.2388 16.8505C21.0788 16.8505 21.7588 17.5305 21.7588 18.3705C21.7588 19.2105 21.0788 19.8905 20.2388 19.8905C19.3988 19.8905 18.7188 19.2105 18.7188 18.3705"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M20.2422 12V13.81"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M20.2422 19.8809V22.0009"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M17 21H4.24219V3H20.2422V10.5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M7.24219 7H17.2422"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M7.24219 13H15.2422"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M7.24219 17H13.2422"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
</template>
