<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_20307_320169)">
      <path
        d="M14.9688 2.01562L2.96875 14.0156L1.96875 18.0156L5.96875 17.0156L17.9688 5.01562L14.9688 2.01562Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M11.9688 4.01562L15.9688 8.01562"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_20307_320169">
        <rect
          width="18.79"
          height="18.79"
          fill="white"
          transform="translate(0.601562 0.605469)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
