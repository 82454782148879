import { useForm } from "vee-validate";
import { object, string, boolean } from "yup";
import { toTypedSchema } from "@vee-validate/yup";
import { FetchError } from "ofetch";

export const useLoginRegisterStore = defineStore("login-register", () => {
  const registerFragment = useRegisterFragment();
  const loginEmailFragment = useLoginEmailFragment();

  const loginStore = useLoginStore();
  const registerStore = useRegisterStore();

  const validationSchema = toTypedSchema(
    object({
      email: string()
        .matches(/^[\w-\.]+(\+[\w-]+)?@([\w-]+\.)+[\w-]{2,9}$/, {
          message: "invalid_email",
        })
        .required("required"),
    }),
  );

  const { meta, defineField, handleSubmit } = useForm({
    validationSchema,
  });

  const submit = handleSubmit(async (values) => {
    const userDoesNotExist = await checkUserExists(values.email);

    if (userDoesNotExist) continueToRegister(values.email);
    else continueToLogin(values.email);
  });

  const checkUserExists = async (email: string) => {
    const result = await $apiFetch<{ isUsernameUnique: boolean }>(
      `/support/unique-username/${email}`,
    );
    return result.isUsernameUnique;
  };

  const continueToLogin = (email: string) => {
    loginStore.email = email;
    loginEmailFragment.open();
  };

  const continueToRegister = (email: string) => {
    registerStore.email = email;
    registerFragment.open();
  };

  const [email, emailAttrs] = defineField("email", {
    validateOnModelUpdate: false,
  });
  const emailError = useFieldError("email");

  return {
    meta,
    email,
    emailAttrs,
    emailError,
    submit,
  };
});

export const useLoginStore = defineStore("login", () => {
  const forgottenPasswordFragment = useForgottenPasswordFragment();
  const passwordResetStore = usePasswordResetStore();

  const validationSchema = toTypedSchema(
    object({
      email: string()
        .matches(/^[\w-\.]+(\+[\w-]+)?@([\w-]+\.)+[\w-]{2,9}$/, "invalid_email")
        .required("required"),
      password: string().required("required"),
    }),
  );

  const {
    meta,
    defineField,
    submitForm: submit,
  } = useForm({
    validationSchema,
  });

  const [email, emailAttrs] = defineField("email", {
    validateOnModelUpdate: false,
  });
  const emailError = useFieldError("email");

  const [password, passwordAttrs] = defineField("password", {
    validateOnModelUpdate: false,
  });
  const passwordError = useFieldError("password");

  const continueToResetPassword = () => {
    passwordResetStore.setFieldValue("email", email.value);
    forgottenPasswordFragment.open();
  };

  return {
    meta,
    email,
    emailAttrs,
    emailError,
    password,
    passwordAttrs,
    passwordError,
    submit,
    continueToResetPassword,
  };
});

export const useRegisterStore = defineStore("register", () => {
  const slideoverRegisterSuccess = useRegisterSuccessSlideoverStore();
  const registerFragment = useRegisterFragment();

  const validationSchema = toTypedSchema(
    object({
      name: string().required("required"),
      email: string()
        .matches(/^[\w-\.]+(\+[\w-]+)?@([\w-]+\.)+[\w-]{2,9}$/, "invalid_email")
        .required("required"),
      password: string().required("required"),
      terms: boolean().default(false),
      privacy: boolean()
        .default(true)
        .required("required")
        .oneOf([true], "required"),
    }),
  );

  const { meta, defineField, handleSubmit } = useForm({
    validationSchema,
  });

  const [name, nameAttrs] = defineField("name", {
    validateOnModelUpdate: false,
  });
  const nameError = useFieldError("name");

  const [email, emailAttrs] = defineField("email", {
    validateOnModelUpdate: false,
  });
  const emailError = useFieldError("email");

  const [terms, termsAttrs] = defineField("terms", {
    validateOnModelUpdate: false,
  });
  const termsError = useFieldError("terms");

  const [privacy, privacyAttrs] = defineField("privacy", {
    validateOnModelUpdate: false,
  });
  const privacyError = useFieldError("privacy");

  const [password, passwordAttrs] = defineField("password", {
    validateOnModelUpdate: false,
  });
  const passwordError = useFieldError("password");

  const { mutate } = useRegisterQuery();

  const submit = handleSubmit((values) => {
    const payload = {
      name: values.name,
      username: values.email,
      password: values.password,
      personalInformationAgreement: values.terms,
    };

    mutate(payload, {
      onSuccess: () => {
        registerFragment.close();
        slideoverRegisterSuccess.open();
      },
    });
  });

  return {
    meta,
    name,
    nameAttrs,
    nameError,
    email,
    emailAttrs,
    emailError,
    password,
    passwordAttrs,
    passwordError,
    terms,
    termsAttrs,
    termsError,
    privacy,
    privacyAttrs,
    privacyError,
    submit,
  };
});

export const usePasswordResetStore = defineStore("password-reset", () => {
  const { mutate, isPending } = useResetPasswordQuery();

  const isResetLinkSent = ref(false);

  const { t } = useI18n({
    useScope: "global",
    messages: {
      cs: {
        resetLinkSent: "Odkaz odeslán",
        resetLinkSentMessage:
          "Nedorazil? Zkontrolujte prosím i spam/složku nevyžádané pošty, než odkaz odešlete znovu",
      },
      en: {
        resetLinkSent: "Reset link sent",
        resetLinkSentMessage:
          "If it hasn't arrived, please check your spam/junk mail folder before resending the link.",
      },
    },
  });

  const validationSchema = toTypedSchema(
    object({
      email: string()
        .matches(/^[\w-\.]+(\+[\w-]+)?@([\w-]+\.)+[\w-]{2,9}$/, "invalid_email")
        .required("required"),
    }),
  );

  const { meta, defineField, handleSubmit, setFieldValue } = useForm({
    validationSchema,
  });

  const [email, emailAttrs] = defineField("email", {
    validateOnModelUpdate: false,
  });
  const emailError = useFieldError("email");

  const notification = useNotification();

  const submit = handleSubmit(({ email }) => {
    mutate(email, {
      onSuccess: () => {
        isResetLinkSent.value = true;
        notification.add("sent", t("resetLinkSent"));
      },
    });
  });

  return {
    meta,
    email,
    emailAttrs,
    emailError,
    submit,
    isResetLinkSent,
    isLoading: isPending,
    setFieldValue,
  };
});
