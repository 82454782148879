export interface CompanyBillingInfo {
  billingAddress: {
    name: string;
    regId: string;
    vatId: string;
    street: string;
    zip: string;
    city: string;
    country: string;
    isBilledWithVat: boolean;
  };
  emails: string[];
  countries: Array<{
    value: string;
    text: string;
  }>;
}

export const useCompanyBillingInfoQuery = (companySlug: MaybeRef<string>) => {
  const queryKey = ["company", companySlug, "billing"];
  return useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const result = await $apiFetch<CompanyBillingInfo>(
        `/admin-api/${unref(companySlug)}/billing`,
      );

      return result;
    },
  });
};
