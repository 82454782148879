import { useMutation } from "@tanstack/vue-query";

export const useShortcutPay = () => {
  return useMutation({
    mutationFn: (variables: {
      companySlug: string;
      orderId: number;
      method: "gateway" | "invoice_promise";
    }) => {
      const data = new FormData();
      data.append("payment[method]", variables.method);

      return $apiFetch(
        `/admin/${variables.companySlug}/objednavka/${variables.orderId}/zaplatit`,
        {
          headers: { Accept: "application/json" },
          method: "POST",
          body: data,
        },
      );
    },
    async onSuccess(data, variables) {
      if (variables.method === "gateway") {
        await navigateTo(data.response.gatewayUrl, {
          external: true,
        });
      }
    },
  });
};
