import { useMutation, useQueryClient } from "@tanstack/vue-query";

export interface BillingData {
  name: string;
  regId?: string;
  vatId?: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  emails?: string[];
}

export const useUpdateCompanyBillingInfoQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables: {
      companySlug: string;
      data: BillingData;
    }) => {
      const result = await $apiFetch<CompanyBillingInfo>(
        `/admin-api/${variables.companySlug}/billing`,
        {
          method: "POST",
          body: variables.data,
        },
      );

      return result;
    },
    onSuccess: (_data, { companySlug }) => {
      queryClient.invalidateQueries({
        queryKey: ["company", companySlug, "billing"],
      });
    },
  });
};
