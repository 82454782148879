import { useMutation } from "@tanstack/vue-query";

export const useResetPasswordQuery = () => {
  const { locale } = useI18n();
  const route = locale.value === "cs" ? "/obnovit-heslo" : "/reset-password";

  return useMutation({
    mutationFn: (username: string) => {
      const body = new FormData();
      body.append("email", username);

      return $apiFetch(route, {
        method: "POST",
        body,
      });
    },
  });
};
