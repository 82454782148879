import { defineStore } from "pinia";

export default function useSlideover() {
  const isOpen = ref(false);
  const open = () => (isOpen.value = true);
  const close = () => (isOpen.value = false);

  return {
    isOpen,
    open,
    close,
  };
}

// Factory method for making slideover stores
const createSlideoverStore = (name: string) =>
  defineStore(`slideover/${name}`, () => ({
    ...useSlideover(),
  }));

// Here we can have all the modal stores at one place, that will make it easier to spot any duplicate names
export const useRegisterSuccessSlideoverStore =
  createSlideoverStore("register-success");
export const usePasswordRenewalSuccessSlideoverStore = createSlideoverStore(
  "password-renewal-success",
);
