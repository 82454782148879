import { useMutation } from "@tanstack/vue-query";

export const useShortcutBuyCredits = () => {
  return useMutation({
    mutationFn: (variables: { companySlug: string; amount: number }) => {
      const data = new FormData();
      data.append("credit_selection[amount]", variables.amount);

      return $apiFetch(`/admin/${variables.companySlug}/nakup-kreditu`, {
        headers: { Accept: "application/json" },
        method: "POST",
        body: data,
      });
    },
  });
};
