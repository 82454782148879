import { useMutation } from "@tanstack/vue-query";

export const usePasswordRenewalQuery = () => {
  const { locale } = useI18n();

  return useMutation({
    mutationFn: (variables: { hash: string; password: string }) => {
      const route =
        locale.value === "cs"
          ? `/password-renewal/${variables.hash}`
          : `/en/password-renewal/${variables.hash}`;

      const body = new FormData();
      body.append("password", variables.password);

      return $apiFetch(route, {
        method: "POST",
        body,
      });
    },
  });
};
